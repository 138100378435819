const Video = ({ className, width, height }) => (
  <iframe
    allowFullScreen
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    className={className}
    height={height}
    src="https://www.youtube.com/embed/JR1Yu3Iu7Hw?si=zmJPn0oJVMRIipjI"
    title="YouTube video player"
    width={width}
  ></iframe>
);
export default Video;
